<div class="container-fluid">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <span class="navbar-brand">Company Documents</span>
      <span class=" mr-auto">
        <button mat-raised-button
          color="primary"
          (click)="onAdd()"
          appMarginRight>
          <mat-icon>add</mat-icon>
          Add Company Document
        </button>

        <mat-menu #appMenu="matMenu">
          <button mat-menu-item (click)="dataSource.selectAllToggle()">
            <mat-icon *ngIf="dataSource.isAllSelected()"> clear_all</mat-icon><mat-icon *ngIf="!dataSource.isAllSelected()">select_all</mat-icon>
            {{ dataSource.isAllSelected() ? 'Deselect' : 'Select' }} All
          </button>
          <button mat-menu-item (click)="onDeleteSelected()" [disabled]="!dataSource.selection.hasValue()">
            <mat-icon>delete</mat-icon> Delete Selected
          </button>
        </mat-menu>

        <button mat-icon-button [matMenuTriggerFor]="appMenu" matTooltip="Use this menu to select/deselect Company Documents." style="min-width: 120px">
          <mat-icon>more_vert</mat-icon>
          List Menu
        </button>
      </span>

      <span class="spinner-container" *ngIf="(dataSource.loading | async)" appMarginRight></span>

      <form class="form-inline my-2 my-lg-0" style="min-width: 325px" (ngSubmit)="dataSource.getData(true)">
        <input
          class="form-control mr-sm-2"
          type="search"
          [(ngModel)]="dataSource.search"
          name="search"
          placeholder="Search"
          aria-label="Search"/>

        <button
          mat-raised-button class="mx-auto my-1 ml-sm-0 mr-sm-2 my-sm-0" color="secondary"
          (click)="dataSource.getData()"
          type="button"
          matTooltip="Run the current search">
          <mat-icon>search</mat-icon> Search
        </button>
      </form>

    </div>
  </nav>

  <div class="mat-elevation-z8 scroll-box">
    <table mat-table
      [dataSource]="dataSource"
      matSort
      matSortActive="name"
      matSortDirection="asc"
      matSortDisableClear>
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="$event ? dataSource.selectAllToggle() : null"
            [checked]="dataSource.selection.hasValue() && dataSource.isAllSelected()"
            [indeterminate]="dataSource.selection.hasValue() && !dataSource.isAllSelected()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? dataSource.selection.toggle(row.id) : null"
            [checked]="dataSource.selection.isSelected(row.id)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let folder">{{ folder.name }}</td>
      </ng-container>

      <ng-container matColumnDef="folder_type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
        <td mat-cell *matCellDef="let folder">{{ folder.folder_type }}</td>
      </ng-container>

      <ng-container matColumnDef="visibility">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Visibility</th>
        <td mat-cell *matCellDef="let folder">{{ folder.visibility }}</td>
      </ng-container>

      <ng-container matColumnDef="expiry">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Expiry Date</th>
        <td mat-cell *matCellDef="let folder">
          {{ folder.expiry ? ((folder.expiry * 1000) | timezoneConvertMedium) : '' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="created_at">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Created</th>
        <td mat-cell *matCellDef="let folder">
          {{ folder.created_at ? (folder.created_at * 1000 | timezoneConvertMedium) : '' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>

        <td mat-cell *matCellDef="let folder">
          <button type="button" mat-button (click)="onView(folder)" matTooltip="View the document information." matTooltipPosition="above">
            View
          </button>

          <button type="button" mat-button (click)="onEdit(folder)" matTooltip="Edit the document information." matTooltipPosition="above">
            Edit
          </button>

          <button type="button" mat-button (click)="onRemove(folder.id)" matTooltip="Remove (Archive) the document." matTooltipPosition="above">
            Delete
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator
      [length]="dataSource?.total | async"
      [pageSize]="dataSource.limit"
      [pageSizeOptions]="dataSource.pageSizeOptions"
      showFirstLastButtons>
    </mat-paginator>
  </div>
</div>

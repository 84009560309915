import { version } from '../../package.json';

export const environment = {
  production: false,
  APP_NAME: 'SetConnect',
  SERVICE_URL: 'https://api.dev.setconnect.sitesoft.com/',
  LARAVEL_API_URL: 'https://api.dev.setconnect.sitesoft.com/api/v1/',
  API_URL: 'https://api.dev.setconnect.sitesoft.com/api/',
  MODE: 'SetConnect-Dev',
  APP_VERSION: version,
  PROJECT_KNOWLEDGEBASE_URL: 'https://docs.setconnect.io/',
  PROJECT_RELEASE_NOTES_URL: 'https://docs.setconnect.io/release-notes/',
  ANDROID_STORE_URL_MOBILE: 'https://play.google.com/store/apps/details?id=com.sitesoft.setconnect',
  ANDROID_STORE_URL_KIOSK: 'https://play.google.com/store/apps/details?id=com.sitesoft.setconnectvk',
  IOS_STORE_URL_MOBILE: 'https://apps.apple.com/us/app/setconnect/id1585597562',
  IOS_STORE_URL_KIOSK: 'https://apps.apple.com/us/app/setconnect-visitor-kiosk/id1596671766',
  SALES_EMAIL: 'sales@sitesoft.com',
  SUPPORT_EMAIL: 'gfssetconnectsupport@sitesoft.com',
  AES_STORAGE_KEY: '!w5ZS*0=J\'e6px]o/lUXO_^ku#9EfF',
  PRIVACY_POLICY_URL: 'https://set-connect.com/privacy-1',
  TERMS_CONDITIONS_URL: 'https://set-connect.com/eula',
  URGENT_NOTICE_URL: 'https://docs.setconnect.io/knowledge-base/urgent-notice-user-update/',
  GOOGLE_ANALYTICS: {
    MEASUREMENT_ID: 'G-5QEG3RRZ36'
  },
  UPVOTY_LINK: 'https://roadmap.setconnect.io/'
};
